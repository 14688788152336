import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid, regular, brands, icon } from '@fortawesome/fontawesome-svg-core/import.macro'; // <-- import styles to be used


const Profile = () => {
	const image = require("../../images/features/image.png");
	const star = require("../../images/features/star.png");
	const empty_star = require("../../images/features/empty-star.png");
	const line = require("../../images/features/line.png");

	return (
		<div className="c-features__profile">
			<div className="c-features__profile--left">
				<img src={image} alt="profile-image"></img>
				<div className="c-features__profile--left__first-button">Book Now</div>
				<div className="c-features__profile--left__second-button">Message</div>

				<div className="c-features__profile--left__availability">
					<span className="c-features__profile--left__availability--heading"><FontAwesomeIcon icon={regular('clock')} /> Availability for the next 7 days</span>

					<div className="c-features__profile--left__availability--first">
						<span>Monday 12th:  9am - 10am</span> <FontAwesomeIcon icon={solid('chevron-right')} />
					</div>
					<div className="c-features__profile--left__availability--second">
						<span>Monday 12th:  9am - 10am</span> <FontAwesomeIcon icon={solid('chevron-right')} />
					</div>
					<div className="c-features__profile--left__availability--third">
						<span>Monday 12th:  9am - 10am</span> <FontAwesomeIcon icon={solid('chevron-right')} />
					</div>
				</div>
			</div>
			<div className="c-features__profile--right">
				<div className="c-features__profile--right__heading">
					<h1>Charlotte</h1>

					<div className="c-features__profile--right__heading--container">
						<div className="c-features__profile--right__heading--reviews">
							<div className="c-features__profile--right__heading--reviews-star-div">
								<div className="c-features__profile--right__heading--reviews-star-div__stars">
									<img src={star} alt="star"></img>
									<img src={star} alt="star"></img>
									<img src={star} alt="star"></img>
									<img src={star} alt="star"></img>
									<img src={empty_star} alt="star"></img>
								</div>
								<div className="c-features__profile--right__heading--reviews-star-div__text">from 14 reviews</div>
							</div>
						</div>

						<div className="c-features__profile--right__heading--price"><span>from<br /> <span className="c-features__profile--right__heading--price__number">£45</span> <br /> Per session</span></div>
					</div>
				</div>
				<img className="c-features__profile--right__line" src={line} alt="line"></img>

				<div className="c-features__profile--right__availability">
					<h5>Available sessions</h5>
					<div className="c-features__profile--right__availability--options">
						<FontAwesomeIcon icon={solid('laptop')} />
						<span>Online</span>
						<FontAwesomeIcon icon={solid('mobile-screen')} />
						<span>Phone Call</span>
						<FontAwesomeIcon icon={solid('house')} />
						<span>Home visit</span>
					</div>
				</div>

				<div className="c-features__profile--right__about">
					<h5>About me</h5>
					<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat  <strong>Read more</strong></p>
				</div>

				<div className="c-features__profile--right__reviews">
					<div className="c-features__profile--right__reviews--heading">
						<h5>Reviews</h5>
						<span>14 reviews</span>
					</div>

					<img src={line} alt="line"></img>

					<div className="c-features__profile--right__reviews__stars">
						<img src={star} alt="star"></img>
						<img src={star} alt="star"></img>
						<img src={star} alt="star"></img>
						<img src={star} alt="star"></img>
						<img src={empty_star} alt="star"></img>
					</div>

					<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat</p>
				</div>
			</div>
		</div>
	);
}

export default Profile;