import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid, regular, brands, icon } from '@fortawesome/fontawesome-svg-core/import.macro'; // <-- import styles to be used
import { useState } from 'react';
import UpcomingAppointments from './upcoming-appointments';

const image = require("../../images/image22.png");
const ellipse1 = require("../../images/ellipse1.png");
const ellipse7 = require("../../images/ellipse7.png");
const ellipse12Mobile = require("../../images/ellipse12_mobile.png");
const arrow1 = require("../../images/vector29.png");
const arrow2 = require("../../images/vector30.png");
const woman = require("../../images/woman.png");
const man = require("../../images/man.png");
const line = require("../../images/line.png");
const bigline = require("../../images/bigline.png");
const snake_line = require("../../images/snake-line.png");

const videocall = require("../../images/videocall-icon.png");
const review = require("../../images/review-icon.png");
const invoice = require("../../images/invoice-icon.png");
const schedule = require("../../images/schedule-icon.png");
const reminder = require("../../images/reminder-icon.png");
const notes = require("../../images/notes-icon.png");
const therapist = require("../../images/therapist.png");

const bookVideoCall = require("../../images/book-videocall.png");
const profileImg = require("../../images/profile.png");
const clientNotesImg = require("../../images/client-notes.png");
const videoConferencingImg = require("../../images/videconference.png");
const calendarImg = require("../../images/calendar.png");

const Home = () => {
	const [imageClicked, setImageClicked] = useState({
		calendar: false,
		videoConferencing: false,
		profile: false,
		videoAppointments: true,
		clientNotes: false,
		emailNotifications: false
	});

	const onClickHandler = (order) => {
		const resetImages = {
			calendar: false,
		    videoConferencing: false,
		    profile: false,
		    videoAppointments: false,
		    clientNotes: false,
		    emailNotifications: false
		}

		setImageClicked({
		    ...resetImages,
			[order]: true
		});
	};

	const renderEllipseImages = () => {
		const images = [];

		for (let i = 2; i < 7; i++) {
			let src = require("../../images/ellipse" + i + ".svg");
			let image = <img src={src} key={i.toString()} className={"c-home__first-section--ellipse" + i}></img>;
			images.push(image);
		}
		return images;
	}

	const renderSecondSectionEllipseImages = () => {
		const images = [];

		for (let i = 9; i < 15; i++) {
			let src = require("../../images/ellipse" + i + ".png");
			let image = <img src={src} key={i.toString()} className={"c-home__second-section--ellipse" + i}></img>;
			images.push(image);
		}
		return images;
	}

	const renderMobileEllipseImages = () => {
		const images = [];

		for (let i = 1; i < 7; i++) {
			let src = require("../../images/ellipse" + i + "_mobile.png");
			let image = <img src={src} key={i.toString()} className={"c-home__first-section--mobile-ellipse" + i}></img>;
			images.push(image);
		}
		return images;
	}

	const renderReviewStarts = () => {
		const images = [];

		for (let i = 0; i < 5; i++) {
			let src = require("../../images/star.png");
			let image = <img src={src} key={i.toString()} className={"c-home__third-section--reviews-block__icons--star"}></img>;
			images.push(image);
		}
		return images;
	}

	const listClassname = imageClicked;

	return (

		<div className="c-home">
			<section>
				<div className="c-home__first-section">
					<div className="c-home__first-section--left">
						<div className="c-home__first-section--left-container">
						    <div className="c-home__first-section--left-container--ellipse">
							    <img src={ellipse1} className="c-home__first-section--ellipse1"></img>
							</div>
							<div className="c-home__first-section--left-container--mobile-ellipses">
								{renderMobileEllipseImages()}
							</div>
							<div className="c-home__first-section--left-container-wording">
								<h1>Run your practice stress-free</h1>
								<p>
								Therapology enables you to manage your practice in one place allowing you to work anywhere, anytime. 
								There is no subscription, or contract to join. With single click video conferencing & payment requests, 
								our all-in-one platform is everything you need to run a stress free practice. 
								</p>
								<a href="https://app.therapology.io/account/register">
								    <div className="c-btn c-btn--primary c-btn__text--center c-home__first-section--left-button">
										Get started for free
								    </div>
								</a>
							</div>
						</div>
					</div>
					<div className="c-home__first-section--right">

						<div className="c-home__first-section--right--ellipses">
							{renderEllipseImages()}
						</div>
						<img src={image} className="c-home__first-section--right-laptop"></img>

					</div>
				</div>
			</section>

			<section>
				<div className="c-home__second-section">
					{renderSecondSectionEllipseImages()}
					<img className="c-home__second-section--ellipse12-mobile" src={ellipse12Mobile}></img>
					<div className="c-home__second-section--heading">
						<h1><span>Smart Workflows </span> <img className="c-home__second-section--heading__img1" src={arrow1}></img><img className="c-home__second-section--heading__img2" src={arrow2}></img> run a happy practice</h1>
					</div>

					<div className="c-home__second-section--features">
						<img className="c-home__second-section--features__ellipse" src={ellipse7} />
						<img className="c-home__second-section--features__bigline" src={bigline}></img>
						<div className="c-home__second-section--features__first">
							<img className="c-home__second-section--features__ellipse8-left" src={schedule} />
							<h3>Schedule an Appointment</h3>
							<div className="c-home__second-section--features__first-calls">
								<div className="c-home__second-section--features__first-calls--video"><FontAwesomeIcon icon={solid('laptop')} />Video Call <div className="c-home__second-section--features__first-calls--video-checklist"><FontAwesomeIcon icon={solid('check')} /></div></div>
								<div className="c-home__second-section--features__first-calls--phone"><FontAwesomeIcon icon={solid('mobile-screen')} />Phone Call</div>
								<div className="c-home__second-section--features__first-calls--face"><FontAwesomeIcon icon={solid('house')} />Face to face</div>
							</div>
						</div>

						<div className="c-home__second-section--features__second">
						    <img className="c-home__second-section--features__ellipse8-right" src={invoice} />
							<img className="c-home__second-section--features__ellipse7-right" src={ellipse7} />
							<hr />
							<div>Raise an Invoice</div>
						</div>

						<div className="c-home__second-section--features__third">
							<img className="c-home__second-section--features__ellipse8-left" src={reminder} />
							<div>Appointment Reminders</div>
							<hr />
							<img className="c-home__second-section--features__ellipse7-left" src={ellipse7} />
						</div>



						<div className="c-home__second-section--features__fourth">
							<img className="c-home__second-section--features__ellipse8-right" src={videocall} />
							<h3>Video Session</h3>
							<div className="c-home__second-section--features__fourth--photos">
								<img className="c-home__second-section--features__fourth--photos-woman" src={woman} alt='woman-picture'></img>
								<img className="c-home__second-section--features__fourth--photos-line" src={line} alt='line-pic'></img>
								<img className="c-home__second-section--features__fourth--photos-man" src={man} alt='man-picture'></img>
							</div>
						</div>

						<div className="c-home__second-section--features__fifth">
							<img className="c-home__second-section--features__ellipse8-right" src={notes} />
							<img className="c-home__second-section--features__ellipse7-right" src={ellipse7} />
							<hr />
							<div>Smart Note Taking</div>
						</div>
						<img className="c-home__second-section--features__snakeline" src={snake_line} />
					</div>
					<div className="c-home__second-section--bottom">

						<div className="c-home__second-section--bottom-heading">
							<h1>Join the community of therapists making their work more <span>efficient</span></h1>
							<img src={therapist} />
						</div>

						<a href="https://app.therapology.io/account/register">
						    <div className="c-btn c-btn--primary c-btn__text--center c-home__second-section--bottom-button">
								Get started for free
						    </div>
						</a>
					</div>

					<div className="c-home__second-section__advantages">
						<div className="c-home__second-section__advantages--first">
							<div className="c-home__second-section__advantages-checklist">
								<FontAwesomeIcon icon={solid('check')} />
							</div>
							<span>No Credit card or contract</span>
						</div>
						<div className="c-home__second-section__advantages--second">
							<div className="c-home__second-section__advantages-checklist">
								<FontAwesomeIcon icon={solid('check')} />
							</div>
							<span>Full Access</span>
						</div>
						<div className="c-home__second-section__advantages--third">
							<div className="c-home__second-section__advantages-checklist">
								<FontAwesomeIcon icon={solid('check')} />
							</div>
							<span>Cancel anytime</span>
						</div>
					</div>
				</div>
			</section>
			<section>
				<div className="c-home__fourth-section">
					<div className="c-home__fourth-section--left">
						<ul>
							<li onClick={() => onClickHandler("calendar")} className={imageClicked.calendar ? "c-home__fourth-section--left-selected" : ""}>Built in calendar</li>
							{imageClicked.calendar && <hr />}
							<li onClick={() => onClickHandler("videoConferencing")} className={imageClicked.videoConferencing ? "c-home__fourth-section--left-selected" : ""}>Single click video conferencing</li>
							{imageClicked.videoConferencing && <hr />}
							<li onClick={() => onClickHandler("profile")} className={imageClicked.profile ? "c-home__fourth-section--left-selected" : ""}>Manage your profile</li>
							{imageClicked.profile && <hr />}
							<li onClick={() => onClickHandler("videoAppointments")} className={imageClicked.videoAppointments ? "c-home__fourth-section--left-selected" : ""}>Book video or face-to-face appointments</li>
							{imageClicked.videoAppointments && <hr />}
							<li onClick={() => onClickHandler("clientNotes")} className={imageClicked.clientNotes ? "c-home__fourth-section--left-selected" : ""}>Keep secure client notes</li>
							{imageClicked.clientNotes && <hr />}
						</ul>
					</div>
					<div className="c-home__fourth-section--right">
					{imageClicked.calendar && <img src={calendarImg} alt="calendar-image"  className='c-home__fourth-section--right__calendar'/>}
					{imageClicked.videoConferencing && <img src={videoConferencingImg} alt="videoconference-image"  className='c-home__fourth-section--right__videoConference'/>}
					{imageClicked.videoAppointments && <img src={bookVideoCall} alt="book-videocall-image"  className='c-home__fourth-section--right__bookVideoCall'/>}
					{imageClicked.profile && <img src={profileImg} alt="profile-image"  className='c-home__fourth-section--right__profile'/>}
					{imageClicked.clientNotes && <img src={clientNotesImg} alt="client-notes-image"  className='c-home__fourth-section--right__clientNotes'/>}
					</div>
				</div>
			</section>

			<section>
				<div className="c-home__fifth-section">
					<h1>Get started for free</h1>
					<p>Sign up today and get 300 FREE mins of video calls</p>

					<a href="https://app.therapology.io/account/register">
					    <div className="c-btn c-btn--primary c-btn__text--center c-home__fifth-section--button">
							Get started for free
					    </div>
					</a>
				</div>
			</section>
		</div>
	);
}

export default Home;