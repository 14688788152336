import React from 'react';

const About = () => {

	const ellipse1 = require("../../images/about/ellipse1.png");
	const ellipse5 = require("../../images/about/ellipse5.png");
	const ellipse6 = require("../../images/about/ellipse6.png");
	const ellipse7 = require("../../images/about/ellipse7.png");
	const line = require("../../images/about/line.png");
	const line1 = require("../../images/about/line1.png");

	const number1 = require("../../images/about/number1.png");
	const number2 = require("../../images/about/number2.png");
	const number3 = require("../../images/about/number3.png");
	const number4 = require("../../images/about/number4.png");

	const line2 = require("../../images/about/line2.png");
	const line3 = require("../../images/about/line3.png");

	const renderEllipseImages = () => {
		const images = [];

		for (let i = 2; i < 5; i++) {
			let src = require("../../images/about/ellipse" + i + ".png");
			let image = <img src={src} key={i.toString()} className={"c-about__first-section--ellipse" + i}></img>;
			images.push(image);
		}
		return images;
	}

	const renderMobileEllipseImages = () => {
		const images = [];

		for (let i = 1; i < 5; i++) {
			let src = require("../../images/about/ellipse" + i + "_mobile.png");
			let image = <img src={src} key={i.toString()} className={"c-about__first-section--ellipse" + i + "-mobile"}></img>;
			images.push(image);
		}
		return images;
	}

	return (
		<div className="c-about">
			<section>
				<div className="c-about__first-section">
					<div className="c-about__first-section--ellipses">
						{renderEllipseImages()}
					</div>
					<div className="c-about__first-section--ellipses-mobile">
						{renderMobileEllipseImages()}
					</div>
					<div className="c-about__first-section--heading">
						<h1>
							<span>Our mission</span> is to give you time back, to spend more it where it's needed.
							On <span>your patients.</span>

						</h1>
						<img className="c-about__first-section--ellipse1" src={ellipse1} alt="ellipse1"></img>
					</div>
				</div>
			</section>


			<section id='our-story'>
				<div className="c-about__second-section">
					<div className="c-about__second-section--heading">
						<ul>
							<li className="c-about__second-section--heading-ourstory"><a href="#our-story">Our Story</a></li>
							<li className="c-about__second-section--heading-whyus"><a href='#why-us'>Why us</a></li>
							<li className="c-about__second-section--heading-theteam"><a href="#the-team">The Team</a></li>
						</ul>
					</div>

					<div className="c-about__second-section--diagram">
						<div className="c-about__second-section--diagram__left">
							<img src={ellipse5} alt="founder-picture"></img>
							<h4>Robin - Founder</h4>
						</div>
						<div className="c-about__second-section--diagram__right">
							<h1>Our Story</h1>
							<img className="c-about__second-section--diagram__right--ellipse6-1" src={ellipse6} alt='ellipse6-img'></img>
							<div className="c-about__second-section--diagram__right-block">
								<div className="c-about__second-section--diagram__right--left">
									<div className="c-about__second-section--diagram__right__first">
										<h4>2018 - Headline</h4>
										<p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi</p>
									</div>
									<img className="c-about__second-section--diagram__right-line1" src={line1} alt='line'></img>
									<img className="c-about__second-section--diagram__right--ellipse6-2" src={ellipse6} alt='ellipse6-img'></img>
									<img className="c-about__second-section--diagram__right-line2" src={line1} alt='line'></img>
									<img className="c-about__second-section--diagram__right--ellipse6-3" src={ellipse6} alt='ellipse6-img'></img>
									<div className="c-about__second-section--diagram__right__second">
										<h4>2020 - Headline</h4>
										<p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi</p>
									</div>
								</div>
								<img className="c-about__second-section--diagram__right--line" src={line} alt="line-img"></img>
								<div className="c-about__second-section--diagram__right--right">
									<img className="c-about__second-section--diagram__right-line3" src={line1} alt='line'></img>
									<img className="c-about__second-section--diagram__right--ellipse6-4" src={ellipse6} alt='ellipse6-img'></img>
									<div className="c-about__second-section--diagram__right__third">
										<h4>2019 - Headline</h4>
										<p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi</p>
									</div>
									<img className="c-about__second-section--diagram__right-line4" src={line1} alt='line'></img>
									<img className="c-about__second-section--diagram__right--ellipse6-5" src={ellipse6} alt='ellipse6-img'></img>
									<div className="c-about__second-section--diagram__right__fourth">
										<h4>2021 - Headline</h4>
										<p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi</p>
									</div>
								</div>
							</div>

							<div className="c-btn c-btn--primary c-btn__text--center c-about__second-section--diagram__right-button">
								<a href="https://app.therapology.io/account/register">
									Join Us
								</a>
							</div>
						</div>
					</div>
				</div>
			</section>


			<section id='why-us'>
				<div className="c-about__third-section">
					<img className="c-about__third-section--ellipse" src={ellipse7} alt='ellipse'></img>
					<h1>Why us</h1>
					<div className="c-about__third-section__blocks">
						<div className="c-about__third-section__blocks--first">
							<div className="c-about__third-section__blocks--first-imgs">
								<img className="c-about__third-section__blocks__numbers" src={number1} alt="number1"></img>
								<img className="c-about__third-section__blocks__lines" src={line2}></img>
							</div>
							<div className="c-about__third-section__blocks--first-text">
								<h4>Why 1</h4>
								<p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi</p>
							</div>
						</div>


						<div className="c-about__third-section__blocks--second">
							<div className="c-about__third-section__blocks--second-imgs">
								<img className="c-about__third-section__blocks__numbers" src={number2} alt="number2"></img>
								<img className="c-about__third-section__blocks__lines" src={line3}></img>
							</div>
							<div className="c-about__third-section__blocks--second-text">
								<h4>Why 2</h4>
								<p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi</p>
							</div>
						</div>
						<div className="c-about__third-section__blocks--third">
							<div className="c-about__third-section__blocks--third-imgs">
								<img className="c-about__third-section__blocks__numbers" src={number3} alt="number3"></img>
								<img className="c-about__third-section__blocks__lines" src={line2}></img>
							</div>
							<div className="c-about__third-section__blocks--third-text">
								<h4>Why 3</h4>
								<p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi</p>
							</div>
						</div>
						<div className="c-about__third-section__blocks-fourth">
							<div className="c-about__third-section__blocks--fourth-imgs">
								<img className="c-about__third-section__blocks__numbers" src={number4} alt="number4"></img>
							</div>
							<div className="c-about__third-section__blocks--fourth-text">
								<h4>Why 4</h4>
								<p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi</p>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section id='the-team'><div className="c-about__fourth-section"></div></section>
		</div>
	);
}

export default About;