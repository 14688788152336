import React from 'react';
import { slide as Menu } from 'react-burger-menu';

const burgerMenu = () => {
	return (
		<Menu>
			<a className="menu-item c-header__burger-links" href="/">
				Home
			</a>

			<a className="menu-item c-header__burger-links" href="https://app.therapology.io/account/register">
			    <div className="c-btn c-btn--primary c-btn__text--center">
					Get started for free
			    </div>
			</a>
			<a className="menu-item c-header__burger-links" href="https://app.therapology.io/account/login">
				Login
			</a>
		</Menu>
	);
};

export default burgerMenu;