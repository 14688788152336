import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid, regular, brands, icon } from '@fortawesome/fontawesome-svg-core/import.macro'; // <-- import styles to be used

const UpcomingAppointments = () => {

	return (
		<div className="c-upcoming">
			<h3>Upcoming Appointments</h3>

			<div className="c-upcoming__first">
				<div className="c-upcoming__first--list">
					<div className="c-upcoming__first--list-hour">9.00 am - 12.00 am</div>
					<div className="c-upcoming__first--list-patient">Daniel Smith</div>
					<div className="c-upcoming__first--list-type"><FontAwesomeIcon icon={solid('laptop')} /> <span>Online</span></div>
					<div className="c-upcoming__first--list-status">
						<div className="c-upcoming__first--list-status__ellipse">
							<div className="c-upcoming__first--list-status__checklist"><FontAwesomeIcon icon={solid('check')} /></div>
						</div>
					</div>
				</div>
			</div>
			<div className="c-upcoming__second">
				<div className="c-upcoming__second--list">
					<div className="c-upcoming__second--list-hour">12.00 am {'>'}{'>'}</div>
					<div className="c-upcoming__second--list-patient">Daniel Smith</div>
					<div className="c-upcoming__second--list-type"><FontAwesomeIcon icon={solid('laptop')} /> <span>Online</span></div>
					<div className="c-upcoming__second--list-status">Start Video Call</div>
				</div>
			</div>
			<div className="c-upcoming__third">
				<div className="c-upcoming__third--list">
					<div className="c-upcoming__third--list-hour">5.00 pm - 6.00 pm</div>
					<div className="c-upcoming__third--list-patient">Sarah Smith</div>
					<div className="c-upcoming__third--list-type"><FontAwesomeIcon icon={regular('building')} /> <span>Face to face</span></div>
					<div className="c-upcoming__third--list-status">View Address</div>
				</div>
			</div>
			<div className="c-upcoming__fourth">
				<div className="c-upcoming__fourth--list">
					<div className="c-upcoming__fourth--list-hour">9.00 am - 12.00 am</div>
					<div className="c-upcoming__fourth--list-patient">Daniel Smith</div>
					<div className="c-upcoming__fourth--list-type"><FontAwesomeIcon icon={solid('laptop')} /> <span>Online</span></div>
					<div className="c-upcoming__fourth--list-status">
						<div className="c-upcoming__fourth--list-status__ellipse">
							<span>Cancelled</span>
							<div>Reschedule {'>'}</div>
						</div>
					</div>
				</div>
			</div>


		</div>
	);


}

export default UpcomingAppointments;