import React, { useState } from 'react';
import FaqData from "react-faq-component";

import data from './faq-content';

const config = {
	animate: true,
	openOnload: 0,
	expandIcon: "+",
	collapseIcon: "-",
};

const FAQ = () => {
const [rows, setRowsOption] = useState(null);
  
	return (
		<div className="c-faq">
			<h2 className="section-title">FAQs</h2>
            <div className="faq-style-wrapper">
                <FaqData data={data} config={config} getRowOptions={setRowsOption} />
            </div>

			<span>Can't find what you're looking for? Send us an email to <a href="mailto:hello@therapology.io">hello@therapology.io</a></span>
		</div>
	);

}

export default FAQ;