import React from 'react';

const ContactUs = () => {

	return (
		<div className="c-contactus">
			<h1>Contact Us</h1>
			<h4>Why not <a href="/faq">check our FAQs</a></h4>

			<form action="mailto:info@w3docs.com" method="get" enctype="text/plain">
				<div className="c-contactus__name">
					<input type="text" name="name" id="name" placeholder="First Name" />
				</div>
				<div className="c-contactus__subject">
					<input type="text" name="subject" id="subject" placeholder="Subject" />
				</div>
				<div className="c-contactus__email">
					<input type="text" name="email" id="email" placeholder="Your email" />
				</div>
				<div className="c-contactus__message">
					<textarea name="comments" rows="12" cols="35">Your message</textarea>
				</div>
				<div className="c-contactus__send">
					<input type="submit" name="submit" value="Send" />
				</div>
			</form>
		</div>
	);
}

export default ContactUs;