import React from "react";

import UpcomingAppointments from "../common/atoms/upcoming-appointments";
import Profile from "./profile"

const Features = () => {
	const vector1 = require("../../images/features/vector1.png");
	const vector2 = require("../../images/features/vector2.png");
	const vector2_mobile = require("../../images/features/vector2_mobile.png");
	const vector3 = require("../../images/features/vector3.png");

	const ellipse1 = require("../../images/features/ellipse1.png");
	const ellipse2 = require("../../images/features/ellipse2.png");
	const ellipse3 = require("../../images/features/ellipse3.png");


	return (
		<div className="c-features">
			<div className="c-features__heading">
				<h4>POWERFUL FEATURES</h4>
				<h1>Built for therapists</h1>

				<a href="https://app.therapology.io/account/register">
				    <div className="c-btn c-btn--primary c-btn__text--center c-features__heading--button">
						Get started for free
				    </div>
				</a>
			</div>

			<section>
				<div className="c-features__first-section">
					<img className="c-features__vector1" src={vector1} alt="vector1"></img>
					<img className="c-features__vector2--mobile" src={vector2_mobile} alt="vector2"></img>
					<img className="c-features__ellipse1" src={ellipse1} alt="ellipse1"></img>

					<div className="c-features__first-section--left">
						<h3>Manage all of your <span>Appointments</span> and <span>Reminders</span></h3>
						<p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi</p>

						<div className="c-features__first-section--left__button">
							<a>Get started for free</a>
						</div>

					</div>

					<div className="c-features__first-section--right">
						<UpcomingAppointments />
					</div>
				</div>
			</section>

			<section className="c-features__second-section">
				<div className="c-features__second-section--left">
					<img className="c-features__vector3" src={vector3} alt="vector3"></img>
					<Profile />
				</div>
				<div className="c-features__second-section--right">
					<img className="c-features__ellipse3" src={ellipse3} alt="ellipse3"></img>
					<h3>Manage your <span>Profile</span> and <span>Reviews</span></h3>
					<p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi</p>

					<div className="c-features__second-section--right__button">
						<a>Get started for free</a>
					</div>
				</div>
			</section>


			<section>
				<div className="c-features__third-section">
					<img className="c-features__vector1" src={vector1} alt="vector1"></img>
					<img className="c-features__vector2--mobile" src={vector2_mobile} alt="vector2"></img>
					<img className="c-features__ellipse1" src={ellipse1} alt="ellipse1"></img>

					<div className="c-features__first-section--left">
						<h3>Manage all of your <span>Appointments</span> and <span>Reminders</span></h3>
						<p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi</p>

						<div className="c-features__first-section--left__button">
							<a>Get started for free</a>
						</div>

					</div>

					<div className="c-features__first-section--right">
						<UpcomingAppointments />
					</div>
				</div>
			</section>

			<section className="c-features__fourth-section">
				<div className="c-features__second-section--left">
					<img className="c-features__vector3" src={vector3} alt="vector3"></img>
					<Profile />
				</div>
				<div className="c-features__second-section--right">
					<img className="c-features__ellipse3" src={ellipse3} alt="ellipse3"></img>
					<h3>Manage your <span>Profile</span> and <span>Reviews</span></h3>
					<p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi</p>

					<div className="c-features__second-section--right__button">
						<a>Get started for free</a>
					</div>
				</div>
			</section>
		</div>
	);

}

export default Features;