const data = {
  rows: [
  {
   title: 'What is Therapology?',
   content: `Therapology is a fully integrated platform which enables you to manage your essential business in one place allowing you to work anywhere, at anytime. There is no subscription, or contract to join Therapology. With single click video conferencing & payment requests, our all-in-one platform is easy and effective to use. The built in calendar allows you to keep on top of your appointments, as well as email notifications and reminders. You can also use Therapology to securely keep client notes which are easily accessible to you at any point.`,
  },
  {
   title: 'How much will it cost to use Therapology?',
   content:' Therapology is a free to use platform, we don’t charge for using the notes or keeping customers appointments and records with us. We charge a small fee if you need to use more than 300 minutes of video call monthly and we charge a small percentage of payments you take through our platform. Each month Therapists receive 300 Minutes of free video call time, should you require extra video call time beyond this the charges are: 5 extra hours: £2.99, 10 extra hours: £5.99, 25 extra hours: £12.99, 35 extra hours: £15.99',
  },
  {
    title: 'How do I access the App?',
    content:'Once you register for Therapology, the app URL will be sent to you via email for you to log into.',
   },
   {
    title: 'Can I meet with clients virtually or do I need a premises?',
    content:'Therapology allows you to choose either of these options. We only help you book your appointments and facilitate the remote ones by helping with the video calling. We do not provide any premises but if you have your own and prefer to do your appointments that way that is also ok.',
   },
   {
    title: 'Are video sessions integrated as part of this platform?',
    content:'Video calls are enabled through Therapology, so you can access all of your essential tools to operate online, including calendar and payment management.',
   },
 ],
 };

export default data;