import ApplicationRouter from "./routes";
import Header from "./components/common/header/index";
import Footer from "./components/common/footer/index";


function App() {
	let header = <Header />;
	let footer = <Footer />;

	return (

		<ApplicationRouter header={header} footer={footer} />
	);
}

export default App;
