import React from 'react';

const Footer = (props) => {
	const facebook = require("../../../images/facebook.png");
	const twitter = require("../../../images/twitter.png");
	const instagram = require("../../../images/instagram.png");

	return (
		<footer>
			<div className="c-footer">
				<div className="c-footer__blocks">
					<div className="c-footer__blocks--first">
						<h4>Ready to get started?</h4>
						<a className="c-btn c-btn--primary c-btn__text--center c-footer__button" href="https://app.therapology.io/account/register">
						    <div>
								Get started for free
						    </div>
						</a>
					</div>
					<div className="c-footer__blocks--second">
						<h5>Our App</h5>
						<ul>
							<li><a href="/">Home</a></li>
						</ul>

					</div>
					<div className="c-footer__blocks--third">
						<h5>Help</h5>
						<ul>
							<li><a href="/faq">FAQs</a></li>
							<li><a href="/contact-us">Contact us</a></li>
						</ul>
					</div>
				</div>

				<div className="c-footer__links">
					<ul>
						<div className="c-footer__links-docs">
							<li className="c-footer__links-docs--terms"><a href="/terms">Terms & Conditions</a></li>
							<li className="c-footer__links-docs--privacy">Privacy Policy</li>
						</div>
						<div className="c-footer__links--social-media">
							<li><a href="https://www.facebook.com/Therapologies"><img className="c-footer__links--facebook" src={facebook} alt="facebook-logo"></img></a></li>
							<li><a href="https://twitter.com/therapologies"><img className="c-footer__links--twitter" src={twitter} alt="twitter-logo"></img></a></li>
							<li><a href="https://www.instagram.com/therapologies/"><img className="c-footer__links--instagram" src={instagram} alt="instagram-logo"></img></a></li>
						</div>

					</ul>

				</div>

			</div>
		</footer>

	);
};

export default Footer;