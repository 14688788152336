import React from 'react';

const Terms = () => {

	return (
		<div className="c-terms">
			<div className='c-terms__container'>
                <h1>SERVICE AND SITE TERMS AND CONDITIONS</h1>
                <p>
                These are our terms and conditions. They apply each time you visit our site. If you use our services, either as a client or as a therapist, they apply each time you use them. We ask that you read and understand them, as they form part of the contract we have with you.
                </p>

                <h1>AGREEMENT</h1>
                <p>These Terms and Conditions comprise the following sections:</p>
                <ul>
                    <li>Site Terms</li>
                    <li>Service Terms – General – applicable to all visitors to the Site</li>
                    <li>Service Terms – Clients – applicable to Clients</li>
                    <li>Service Terms – Therapists – applicable to Therapists</li>
                    <li>Definitions</li>
                    <li>Privacy Notice</li>
                </ul>
                <p>
                    which together form the basis of the agreement between you and us, and you and we agree to be bound by what each section says.
                </p>
                <p>
                    If you choose to take any of the Services we offer, no binding agreement will exist between you and us until we have confirmed, by email to you, that we have accepted you as a client. Any payment you make to us will be refunded to you in full if we do not accept you as a client.
                </p>

                <h1>SITE TERMS</h1>
                <p>These terms apply each time you visit the Site, whether or not you use the Services we offer.</p>
                <h3>1.	YOUR PROMISES TO US</h3>
                <p>You agree that:</p>
                <ul className='c-terms__ul-nodecoration'>
                   <li>1.1	You have the right to make this Agreement with us and that you are over the age of 18 years.</li>
                   <li>1.2	You will read the terms and conditions on any site we link you to.</li>
                   <li>1.3	You won’t use robots, spiders, scrapers or similar things on the Site.</li>
                   <li>1.4	You won’t try to get around any things we put on the Site to stop or limit access to parts of it.</li>
                   <li>1.5	You won’t do anything that might cause our systems to crash.</li>
                   <li>1.6	You won’t steal the Site or any part of it for use in any other site or application.</li>
                   <li>1.7	You won’t try to modify, translate, adapt, edit, decompile, disassemble or reverse engineer any programs we use in connection with the Site or the services it offers.</li>
                   <li>1.8	You won’t copy, imitate or use the trademarks and/or designs and/or layout or anything else which would usually amount to intellectual property and which we own.</li>
                </ul>
                <h3>2.	INTELLECTUAL PROPERTY</h3>
                <p>Either we or our business partners and affiliates own all of the information and intellectual property on the Site and you have no right to copy or use any of that information or intellectual property other than to use the Site unless we give that right to you.</p>
                <h3>3.	PRIVACY AND COOKIES</h3>
                <p>You and we both agree that our Privacy Notice forms part of these Terms and Conditions.</p>
                <h3>4.	DISCLAIMERS</h3>
                <ul className='c-terms__ul-nodecoration'>
                   <li>4.1	We can’t promise that the Site will meet your needs; that it will work properly; that it will be fit for a particular purpose or that it will not infringe the rights of others.</li>
                   <li>4.2	We can’t promise that the Site will work with all systems; that it will be secure and that all information provided will be accurate.</li>
                   <li>4.3	We don’t give advice on the Site, just general opinions, and so you must not rely on what we say when you make any decisions. You must always consult a medical professional if you are in any doubt.</li>
                   <li>4.4	We take all reasonable effort to test material before placing it on the Site. In the very unlikely event of any loss, disruption or damage, we cannot be held responsible for any loss, disruption or damage to your data or computer system which may occur whilst using material derived from the Site.</li> 
                   <li>4.5	If you link to any other site using the Site then you understand that separate conditions will apply to those sites and that we have no control over those conditions – so you agree that you will read and understand them before using those sites.</li> 
                </ul>
                <h3>5.	AVAILABILITY OF THE SITE</h3>
                <ul className='c-terms__ul-nodecoration'>
                    <li>5.1	We never guarantee that the Site will be available all the time and if it’s not available for any reason you can’t hold us responsible for anything you lose as a result.</li>
                    <li>5.2	We have the right to change the Site and the services it offers, suspend it or stop it at any time.</li>
                </ul>
                <h3>6.	LIMITATION OF LIABILITY</h3>
                <ul className='c-terms__ul-nodecoration'>
                    <li>6.1	As far as we are allowed by law we deny liability for any losses of all kinds which you incur from visiting and/or using the Site. You use the Site at your own risk. </li>
                    <li>6.2	Nothing in these Terms and Conditions excludes or restricts our liability for death or personal injury resulting from any negligence or fraud on our part.</li> 
                </ul>
                <h3>7.	LINKS TO OTHER WEBSITES</h3>
                <ul className='c-terms__ul-nodecoration'>
                    <li>7.1	We don’t control any of the websites we link to and so we can’t be responsible for the content of such websites and we disclaim liability for any losses which come out of you using them.</li>
                    <li>7.2	Just because we link to a site does not mean that we endorse or recommend that site.</li>
                    <li>7.3	We can never guarantee that a link will work.</li>
                </ul>
                <h3>8.	MODIFICATIONS TO THESE TERMS AND CONDITIONS AND THE SITE</h3>
                <ul className='c-terms__ul-nodecoration'>
                    <li>8.1	You agree to re-read these Terms and Conditions each time you visit the Site so as to understand any changes we’ve made to them.</li>
                    <li>8.2	If we change the Site these Terms and Conditions will apply to any changes we make.</li>
                </ul>
                <h3>9.	GENERAL MATTERS</h3>
                <ul className='c-terms__ul-nodecoration'>
                    <li>9.1	These Terms and Conditions are made under the laws of England and Wales and that is the only jurisdiction which can govern them.</li>
                    <li>9.2	We and you agree that these Terms and Conditions do not form the basis of any partnership or co-venture.</li>
                    <li>9.3	These Terms and Conditions supersede any previous terms and conditions we have published and represent the entire understanding between you and us.</li>
                    <li>9.4	Time will not be of the essence in any part of any agreement between you and us.</li>
                    <li>9.5	All parties acknowledge and agree that they have not entered into any agreement between them in reliance on anything said or promised by the other which is not in these Terms and Conditions.</li>
                    <li>9.6	If a Court or other body says that any part of these Terms and Conditions is unenforceable, the rest of them will stand.</li>
                    <li>9.7	If either you or we need to give formal notice to the other, it must be done by email to the address each of us gives to the other from time to time.</li>
                    <li>9.8	These Terms and Conditions contain the entire understanding between you and us. </li>
                    <li>9.9	In these Terms and Conditions, unless the context otherwise requires, the words and phrases contained within the Definitions Section will have the meanings ascribed to them in that section.</li>
                </ul>

                <h1>SERVICE TERMS – GENERAL</h1>
                <h3>THESE TERMS APPLY BOTH TO CLIENTS AND TO THERAPISTS</h3>
                <h3>1.	Our Obligations</h3>
                <ul className='c-terms__ul-nodecoration'>
                    <li>1.1	We will provide the Services with reasonable skill and care. </li>
                    <li>1.2	We will act using the information with which you have provided us, as long as it complies with the standards we set on the Site. </li>
                    <li>1.3	We will comply with all statutes, regulations, byelaws, standards, codes of conduct and any other rules relevant to the provision of the Services.</li>
                </ul>
                <h3>2.	Accounts</h3>
                <ul className='c-terms__ul-nodecoration'>
                    <li>2.1 When you create an Account you promise that: </li>
                    <li>
                        <ul className='c-terms__ul-nodecoration'>
                            <li>2.1.1 You will only have one Account with us but, if you wish to use our services as a Client and a Therapist, you must create separate accounts for each role;</li>
                            <li>2.1.2 All information you submit is accurate and truthful; </li>
                            <li>2.1.3 You will keep this information accurate and up-to-date; </li>
                            <li>2.1.4 You will not share your Account with anyone else; </li>
                            <li>2.1.5 You will keep your Account details confidential; </li>
                            <li>2.1.6 You will not give your username or password to anyone else; </li>
                            <li>2.1.7 You will log off when you exit the Site; and</li>
                            <li>2.1.8 Your username will not be offensive, suggest that you are someone else or that you represent a trade or brand name. We can change a username if we think it breaks this term.</li>
                        </ul>
                    </li>
                    <li>2.2	We may close your Account if you break these Terms and Conditions or if there has been no activity on the Account for 12 months. </li>
                    <li>2.3	If any payment we send to your bank account is refused and you do not supply us with an alternative bank account within 28 days of us asking you, we may keep that payment to cover our costs and you will be entitled to <strong>nothing</strong>.</li>
                    <li>2.4	If you have not authenticated your current email address with us and/or if it cannot accept service emails from us and/or you mark our emails as spam, abusive or junk then, and in any such event, we can terminate your Account.</li>
                    <li>2.5	We may keep any commission or interest we receive on the money deposited in your Account.	</li>
                </ul>
                <h3>3.	The Services</h3>
                <ul className='c-terms__ul-nodecoration'>
                    <li>3.1	The Service we provide is designed to put those requiring therapy in touch with those that provide services which might help. </li>
                    <li>3.2	We do not offer any therapy services ourselves.</li>
                    <li>3.3	Because we do not provide therapy, we have no responsibility for the quality of work undertaken by any Therapist nor have we any control over any Client and so we can make no warranties that any claim made by either Therapist or Client is correct.</li>
                </ul>
                <h3>4.	Liability, Indemnity and Insurance</h3>
                <ul className='c-terms__ul-nodecoration'>
                    <li>4.1	You accept that we have no liability to you if you provide us with information which is inaccurate or incomplete. </li>
                    <li>4.2	We will have in place at all times suitable and valid professional indemnity and public liability insurance. </li>
                    <li>4.3	Our total liability for any loss or damage caused as a result of our negligence or breach of the Agreement shall be limited to the cover provided by our professional indemnity policy.</li>
                    <li>4.4	We will not be liable for any loss or damage you suffer that results from your failure to follow any instructions we give you. </li>
                    <li>4.5	We will not be liable to compensate you for special damages, loss of profit or opportunity, indirect or consequential loss. </li>
                    <li>4.6	Nothing in these Terms and Conditions or in the Agreement shall limit or exclude our liability for death, personal injury or fraud. </li>
                    <li>4.7	No Party shall be liable to the other or be deemed to be in breach of the Agreement by reason of any delay in performing, or any failure to perform, any of that Party’s obligations if the delay or failure is due to any cause beyond that Party’s reasonable control.</li>
                </ul>
                <h3>5.	Force Majeure</h3>
                <ul className='c-terms__ul-nodecoration'>
                    <li>Neither you nor we shall be liable for any failure or delay in performing our obligations where such failure or delay results from any cause that is beyond the reasonable control of the party in default. Such causes might include, but are not limited to: power failure, internet contractor failure, industrial action, civil unrest, fire, flood, storms, earthquakes, acts of terrorism, acts of war, pandemic, governmental action or any other event that is beyond our or your control.</li>
                </ul>
                <h3>6.	No Waiver</h3>
                <ul className='c-terms__ul-nodecoration'>
                    <li>If you or we fail or delay in exercising any of your/our rights under the Agreement that failure or delay will not be a waiver of that right, and no waiver shall be deemed to be a waiver of any further breach of the same or any other provision.</li>
                </ul>
                <h3>7.	Assignment and Sub-Contracting</h3>
                <ul className='c-terms__ul-nodecoration'>
                    <li>7.1	The Agreement is personal to you and you may not assign, mortgage, charge or sub-license or otherwise delegate any of your rights thereunder, or sub-contract or otherwise delegate any of your obligations thereunder without our written consent. </li>
                    <li>7.2	We may use suitably qualified and skilled sub-contractors to help us perform the Services.</li>
                </ul>
                <h3>8.	Relationship of the Parties</h3>
                <ul className='c-terms__ul-nodecoration'>
                    <li>The Agreement is not a partnership or joint venture, agency.. The only contractual relationship between the Parties is the one shown in the Agreement.</li>
                </ul>
                <h3>9.	Third Party Rights</h3>
                <ul className='c-terms__ul-nodecoration'>
                    <li>No part of the Agreement shall confer rights on any third parties and accordingly the Contracts (Rights of Third Parties) Act 1999 shall not apply to the Agreement.</li>
                </ul>
                <h3>10.	Notices</h3>
                <ul className='c-terms__ul-nodecoration'>
                    <li>10.1 All notices under the Agreement shall be in writing and be deemed duly given if signed by, or on behalf of, a duly authorised officer of the Party giving the notice.</li>
                    <li>10.2 Notices shall be deemed to have been duly given when sent by email to the last known email address of the Party in question.</li>
                </ul>
                <h3>11.	Entire Agreement</h3>
                <ul className='c-terms__ul-nodecoration'>
                    <li>11.1 The Agreement (constituting the Site Terms Service Terms – General Service Terms – Clients	Service Terms – Therapists – Definitions – Privacy Notice) contains the entire agreement between you and us and may not be modified except by an instrument in writing signed by the duly authorised representatives of the Parties. </li>
                    <li>11.2 Each party acknowledges that, in entering into the Agreement, it has not relied on any representation, warranty or other provision except as expressly provided in the Agreement, and all conditions, warranties or other terms implied by statute or common law are excluded to the fullest extent permitted by law.</li>
                </ul>
                <h3>12.	Severance</h3>
                <ul className='c-terms__ul-nodecoration'>
                    <li>If one or more of the provisions of these Terms and Conditions is found to be unlawful, invalid or otherwise unenforceable, that / those provision(s) shall be deemed severed from the remainder of these Terms and Conditions. The remainder of these Terms and Conditions shall be valid and enforceable.</li>
                </ul>
                <h3>13.	Law and Jurisdiction</h3>
                <ul className='c-terms__ul-nodecoration'>
                    <li>The laws of England and Wales will apply to the Agreement and any dispute between you and us will fall within the jurisdiction of the courts of England and Wales.</li>
                </ul>
                <h3>14.	Dispute Resolution</h3>
                <ul className='c-terms__ul-nodecoration'>
                    <li>14.1 Clients and Therapists shall attempt to resolve any dispute arising out of or relating to the Agreement through negotiations between their appointed representatives who have the authority to settle such disputes. </li>
                    <li>14.2 If negotiations under sub-Clause 14.1 do not resolve the matter within 7 days of receipt of a written invitation to negotiate, the Parties will attempt to resolve the dispute in good faith through an agreed Alternative Dispute Resolution (“ADR”) procedure. </li>
                    <li>14.3 If the ADR procedure under sub-Clause 14.2 does not resolve the matter within 14 days of the initiation of that procedure, or if either Party will not participate in the ADR procedure, the dispute may be referred to arbitration by either the Client or the Therapist . </li> 
                    <li>14.4 The seat of the arbitration under sub-Clause 14.3 shall be England and Wales. The arbitration shall be governed by the Arbitration Act 1996 and Rules for Arbitration as agreed between the Parties. In the event that the Parties are unable to agree on the arbitrator(s) or the Rules for Arbitration, either Party may, upon giving written notice to the other Party, apply to the President or Deputy President for the time being of the Chartered Institute of Arbitrators for the appointment of an arbitrator or arbitrators and for any decision on rules that may be required. </li>
                    <li>14.5 Nothing in this Clause 14 shall prohibit either the Therapist or the Client from applying to a court for interim injunctive relief. </li>
                    <li>14.6 The decision and outcome of the final method of dispute resolution under this Clause 14 shall be final and binding on both the Therapist and the Client.</li>
                </ul>
                <h1>SERVICE TERMS – CLIENTS</h1>
                <h3>1.	Our Responsibilities</h3>
                <ul className='c-terms__ul-nodecoration'>
                    <li>1.1	We will provide you with a list of Therapists who may be able to assist you, when you register with our Services.</li>
                    <li>1.2	Our responsibility is to use our reasonable endeavours to locate a Therapist who may be able to assist you.</li>
                    <li>1.3 We do not supply therapy ourselves.</li>
                    <li>1.4	Because we do not provide therapy ourselves, we make no warranty that:</li>
                    <ul>
                        <li>1.4.1 we will be able to locate any Therapist suitable for your needs; or</li>
                        <li>1.4.2 any of our Therapists will be able to assist you; or</li>
                        <li>1.4.3 the Therapist you choose is suitable for your needs, is qualified in any manner and will be able to assist you.</li>
                    </ul>
                    <li>1.5	We will only ask you for basic information relating to your identity and needs; we will never ask for information which is more detailed than that and all information we hold will be held under our Privacy Policy. </li>
                </ul>
                <h3>2.	Your Responsibilities</h3>
                <ul className='c-terms__ul-nodecoration'>
                    <li>2.1	To ensure that you receive the correct advice and assistance, you warrant and agree:</li>
                    <ul className='c-terms__ul-nodecoration'>
                        <li>2.1.1 that all information you give to us or a Therapist is complete, up-to-date and accurate;</li>
                        <li>2.1.2 that you will keep all and any Appointments which are made for you; and</li>
                        <li>2.1.3 that all communication or any direct contact with a Therapist is conducted using secure means, and that we will not be responsible (and neither can the Therapist) for the divulgence of private information to unauthorised information, as a result of the means of communication you have chosen.</li>
                    </ul>
                    <li>2.2	Before you undertake any therapy, you will agree, with the Therapist, its terms and conditions. By undertaking any therapy, you will be deemed to have accepted any terms which you have been shown.</li>
                    <li>2.3	You agree to take such steps as you feel appropriate to verify the qualifications, experience and suitability of any Therapist you consult and accept that we have not conducted any background checks on any Therapist we suggest.</li>
                    <li>2.4	If undertaking any face-to-face communications with a Therapist, you will take all steps which are reasonable to ensure your personal safety.</li>
                    <li>2.5 You must provide a suitable environment for the Therapy.</li>
                    <li>2.6 You must provide a safe environment for the Therapist.</li>
                </ul>
                <h3>3.	Payment</h3>
                <ul className='c-terms__ul-nodecoration'>
                    <li>3.1	No Therapist will undertake any work with you unless you have deposited in your Account the amount you have agreed with the Therapist.</li>
                    <li>3.2	We will hold the sum you have deposited with us in your Account until either you or the Therapist informs us that the service you have paid for has been completed. Subject to sub-Clause 3.3, upon receiving confirmation that the service you have paid for has been completed, we will release the Fee to the Therapist, less the commission due to us from the Therapist.</li>
                    <li>3.3	If either you or the Therapist feel that a dispute has arisen, then the Party claiming the dispute must contact us, within 2 working days of the completion of the therapy or the start of such dispute. You must then invoke the Dispute Resolution Procedure in Clause 14 of the Service Terms – General and we will hold payment until we have been notified that the dispute has been resolved and upon application of both Client and Therapist. The amount we will retain pursuant to this Clause is the amount due to the Therapist less the commission to which we are entitled, and we may retain such commission for our own benefit.</li>
                    <li>3.4	For the sake of clarity, you understand that the contract you have for the provision of therapy is with the Therapist and not with us and that we have no financial obligation to you in relation to the services offered by a Therapist, save as expressly stated herein.</li>
                </ul>
                <h3>4.	Cancellation</h3>
                <ul className='c-terms__ul-nodecoration'>
                    <li>4.1	As a consumer you have a statutory right to cancel your Agreement with us up to 14 calendar days after the Agreement has been formed. You may cancel the Agreement with us for any reason under this right. If you wish to cancel the Agreement after a Therapist has started to provide its services, this right will not apply and, if you wish to cancel, you may lose part or all of your fee.</li>
                    <li>4.2	If you wish to exercise your right to cancel under this Clause 4, you must tell us as soon as possible. You may do so in any way that is convenient for you. Please ensure that you tell us of your decision to cancel before the period in sub-Clause 4.1 expires (note that the cancellation period is defined as whole calendar days. You may cancel by using the “cancel button” on the Site or you may contact us by email on customersupport@smartconsign.io. </li>
                    <li>4.3	Any refund to which you may be entitled under this Clause 4 will be issued to you no later than 14 calendar days after the date on which you advise us that you wish to cancel.</li>
                    <li>4.4	Refunds under this Clause 4 will be made using the same payment method you used when making payment to us. </li>
                    <li>4.5	Should you wish to cancel the services provided by a Therapist after the period in sub-Clause 4.1 you will be liable to pay the penalties contained in the Therapist’s terms and conditions.</li>
                </ul>
                <h1>SERVICE TERMS – THERAPISTS</h1>
                <h3>1.	How We Provide Referrals</h3>
                <ul className='c-terms__ul-nodecoration'>
                    <li>1.1	From the date upon which we list your services as a Therapist until the Term expires, we will provide Referrals to you from time to time.</li>
                    <li>1.2	We will do our best to introduce Referrals to you on a regular basis, but we have no liability if that is not possible on a regular basis or at all. </li>
                    <li>1.3	We will always take reasonable skill and care when providing Referrals to you, but you accept that, in most circumstances, we have no prior knowledge of the Client.</li>
                    <li>1.4	We will comply with all laws relating to the Services we provide.</li>
                </ul>
                <h3>2.	Your Obligations</h3>
                <ul className='c-terms__ul-nodecoration'>
                    <li>2.1	You will supply us with the information we need to provide our Services to you. </li>
                    <li>2.2	If we need a decision or other communication from you in connection with any Referral, we ask you to supply it within 4 hours of our request.</li>
                    <li>2.3	You will upload, to the Site, a copy of all / any qualifications and any licence/s to practice you have or acquire during the Term and any changes which are made in them, within 24 hours of such change being made.</li>
                    <li>2.4	You warrant that the qualifications you have told us you have are real, current and not subject to any conditions you have not told us about.</li>
                    <li>2.5	If, during the Agreement, any restrictions of any nature are imposed upon you which affect your ability to provide a professional service to any Client and/or if any qualification or licence you hold is revoked or made subject to any conditions, you will advise us as soon as you can.</li>
                    <li>2.6	If we offer you a Referral, we ask that you accept/reject that offer within 4 hours.</li>
                    <li>2.7	If you have accepted a Referral and, for any reason, you cannot make the Appointment you have made, you must advise the Client as soon as you are aware of the issue and arrange a new Appointment with the Client. If this is not possible, you must cancel the Appointment so that we may pass the Referral to another therapist.</li>
                    <li>2.8	In order for us to send you Referrals we ask that you have, at all times during the Agreement, a full and effective insurance policy covering both professional indemnity and public liability, each for cover of at least £2,000,000. You will upload legible copies of the certificate and policy for such insurance to the Site and any changes which are made to such policy, if any such changes are made, within 1 working day of such change.</li>
                    <li>2.9	You will always conduct yourself:</li>
                    <li>
                        <ul className='c-terms__ul-nodecoration'>
                            <li>2.9.1 in a professional manner;</li>
                            <li>2.9.2 to the highest standards of service and of your profession; and</li>
                            <li>2.9.3 in accordance with the rules of any professional body of which you are a member.</li>
                        </ul>
                    </li>
                    <li>2.10	You will take care not to cause damage to or harm our reputation and you will, at all times, act in the utmost good faith, both in your dealings with us and with any Client.</li>
                </ul>
                <h3>3.	Fees, Payment and Records</h3>
                <ul className='c-terms__ul-nodecoration'>
                    <li>3.1	We will pay you the Fee less our commission within 7 days of being notified by the Client that the Appointment has taken place or receipt from you of an invoice (which should be a VAT invoice if you are registered for VAT) for the Fee (whichever is the later), less any reasonable retention we decide in connection with any disputes of which we are aware at the time payment is due to you.</li>
                    <li>3.2	Payment of the Fee will be to the bank, details of which you have supplied to us or at our choice using PayPal or a similar service.</li>
                    <li>3.3	If any payment from us is rejected, for any reason, we will notify you and endeavour to make such payment a second time. You will pay us a fee of £30 to cover the administrative and other expenses we incur.</li>
                </ul>
                <h3>4.	Liability, Indemnity and Insurance</h3>
                <ul className='c-terms__ul-nodecoration'>
                    <li>4.1	If we do not act with reasonable care and skill we will correct the problem we have caused at no additional cost to you. </li>
                    <li>4.2	Our total liability to you for any loss or damage caused as a result of its negligence or breach of the Agreement will be limited to the amount of the Fee.</li>
                    <li>4.3	You will indemnify us against any costs, liability, damages, loss, claims or proceedings arising in any way from loss or damage we sustain and which you have caused. </li>
                </ul>
                <h3>5.	Confidentiality</h3>
                <ul className='c-terms__ul-nodecoration'>
                    <li>Each Party agrees, at all times (both during the Term and after it has expired), to keep all information relating to any Client confidential and not to disclose it to any third party unless required by law to do so or with the consent of that Client.</li>
                </ul>
                <h3>6.	Term and Termination</h3>
                <ul className='c-terms__ul-nodecoration'>
                    <li>6.1	The Agreement will last until it is brought to an end under the following provisions.</li>
                    <li>6.2	Either you or we can end the Agreement by giving to the other no less than 1 month’s written notice, to expire at any time.</li>
                    <li>6.3	One Party may end the Agreement immediately by giving written notice to the other if: </li>
                    <li>
                        <ul className='c-terms__ul-nodecoration'>
                            <li>6.3.1 any monies owed to it are not paid within 10 Business Days of their due date;</li>
                            <li>6.3.2 the other Party breaks a provision of the Agreement and, if the breach can be repaired (in all respects), does not repair it within 10 Business Days of being given written notice of it and asking for it to be repaired;</li>
                            <li>6.3.3 the other becomes insolvent, bankrupt, has a winding up order made against it or goes into liquidation (unless for reconstruction purposes); or</li>
                            <li>6.3.4	the other ceases, or threatens to cease, to carry on business.</li>
                        </ul>
                    </li>
                    <li>6.4	The ending of the Agreement does not prejudice the right of either Party to take action for previous breaches.</li>
                </ul>
                <h3>7.	Status</h3>
                <ul className='c-terms__ul-nodecoration'>
                    <li>7.1	You acknowledge that your agreement with us is not an employment contract; that you are self-employed and that it is your responsibility to pay all tax, duties, levies and other impositions on the Fee.</li>
                    <li>7.2	You will indemnify us against any payments we have to make as a result of any tax, duty, levy or other impositions on the Fee, which we are obliged to make on your behalf and will pay us, in full, all monies we have expended in this manner, within 7 days of a request from us to do so and, if such monies remain outstanding after such request, you will pay us interest at 10% per annum on the balance due, from the due date until the day we actually receive payment.</li>
                </ul>
                <h1>DEFINITIONS</h1>
                <table className='c-terms__generaldefinitions'>
                    <tr>
                        <td>We, Us, Our</td>
                        <td>means Therapologies Ltd company number 11855166, registered address Brulimar House Jubilee Rd Middleton Manchester M24 4LX</td>
                    </tr>
                    <tr>
                        <td>You, Your</td>
                        <td>means a visitor to the Site;</td>
                    </tr>
                    <tr>
                        <td>Account</td>
                        <td>means the account you create if you wish to use our Services, to which we will credit any monies due to you and hold relevant information; </td>
                    </tr>
                    <tr>
                        <td>Agreement</td>
                        <td>means the agreement which comes into effect when you accept these Terms and Conditions;</td>
                    </tr>
                    <tr>
                        <td>App</td>
                        <td>means the application entitled Therapology®;</td>
                    </tr>
                    <tr>
                        <td>Appointment</td>
                        <td>means the appointment between a Therapist and a Client;</td>
                    </tr>
                    <tr>
                        <td>Business Day</td>
                        <td>means any day (other than Saturday or Sunday) on which ordinary banks are open for their full range of normal business in London;</td>
                    </tr>
                    <tr>
                        <td>Client</td>
                        <td>means any entity wishing to use the Services to seek a Therapist;</td>
                    </tr>
                    <tr>
                        <td>Content</td>
                        <td>means any text, graphics, images, audio, video, software, data compilations and any other form of information capable of being stored in a computer that appears on or forms part of the Site;</td>
                    </tr>
                    <tr>
                        <td>Fee</td>
                        <td>means any and all sums due from you to a Therapist, from time to time;</td>
                    </tr>
                    <tr>
                        <td>Feedback</td>
                        <td>means the opinion of the Client or Therapist, as the case may be, of the other, accompanied by a star rating;</td>
                    </tr>
                    <tr>
                        <td>Project</td>
                        <td>means the details of a job which a Client wishes to have undertaken by a Therapist;</td>
                    </tr>
                    <tr>
                        <td>Therapist</td>
                        <td>means any service provider wishing to provide its services to Clients using the Services;</td>
                    </tr>
                    <tr>
                        <td>Referral</td>
                        <td>means the referral of a Client to a Therapist using the Services;</td>
                    </tr>
                    <tr>
                        <td>Services</td>
                        <td>means the services we are to provide to you and which you have subscribed for through the Site. In particular, we provide a service to our Clients, putting them in touch with suitable therapists; in addition we provide general advice and assistance on the Site; </td>
                    </tr>
                    <tr>
                        <td>Site</td>
                        <td>means Therapology.io and/or the Therapology® App;</td>
                    </tr>
                    <tr>
                        <td>Terms and Conditions</td>
                        <td>means these terms and conditions; and</td>
                    </tr>
                    <tr>
                        <td>User</td>
                        <td>means any person using the Site.</td>
                    </tr>   
                </table>
                <h1>PRIVACY NOTICE</h1>
                <h1>Therapologies Limited</h1>
                <p>Therapology® and <a href='www.therapology.io'>www.therapology.io</a> are the trading names owned and operated by Therapologies Limited.</p>
                <p>
                   We offer a service to consumers and others putting them in touch with providers of therapy and other services.
                   This notice is designed to give you clear information about what Personal Data we collect and how we use it. This Privacy Notice describes how we the use Personal Data processed by us and covers the steps we take, when you contact us using the Site. 
                </p>
                <h3>Definitions</h3>
                <p>To help make this Privacy Notice a little easier to read, we have defined certain words and phrases. Those definitions are as follows:</p>
                <table className='c-terms__definitions'>
                    <tr>
                        <td>GDPR</td>
                        <td>the General Data Protection Regulation EU 2016/679.</td>
                    </tr>
                    <tr>
                        <td>Personal Data</td>
                        <td>the data/information we hold about any data subject – as defined further by GDPR.</td>
                    </tr>
                    <tr>
                        <td>Privacy Notice</td>
                        <td>the provisions contained in this document.</td>
                    </tr>
                    <tr>
                        <td>Site:</td>
                        <td><a href='www.therapology.io'>www.therapology.io </a></td>
                    </tr>
                </table>
                <h3>Who we are</h3>
                <p>
                   We are Therapologies Limited and we trade as Therapology®. Our company number is 11855166 and our registered address is Brulimar House, Jubilee Rd, Middleton, Manchester M24 4LX. We are registered with the Information Commissioner’s Office under registration number ZA748762.
                   We are the Data Controller for Personal Data which we use to provide the services which we describe on the Site and for marketing purposes.
                   We can be contacted at <a href='mailto:support@therapology.io'>support@therapology.io</a> 
                </p>
                <h3>How do we collect and use Personal Data and what types of Personal Data do we collect?</h3>
                <p>
                   When you use the Site, we store Personal Data about your visit using cookies.
                   Cookies are text files placed on your device to collect standard internet log information and visitor behaviour information. This information is used to track visitor use of the Site and to compile statistical reports on Site activity.
                </p>
                <p>For further information about cookies visit:</p>
                <a href='www.aboutcookies.org'>www.aboutcookies.org</a>
                <p>and / or</p>
               <a href='www.allaboutcookies.org'>www.allaboutcookies.org</a>
               <p>Through the use of cookies, we collect certain Personal Data, including: IP address, identification number, online identifier, browser information, location data and other similar identifying information required for your devices to communicate with websites and other applications on the internet.  We use that information to provide our Services and, from time to time, for marketing purposes, so that we can suggest offers and companies who may be able to assist you.</p>
               <p>You can set your browser not to accept cookies and the above websites advise you how to remove cookies from your browser. However, in a few cases some of our Site features may not function as a result.</p>
               <h3>Using our Site </h3>
               <p>The Personal Data we hold for you will be held for no longer than we need it. Usually, if you are a private individual and not a business, we will not retain any Personal Data after your Account has been closed, and if you have no Account, then, normally, no longer than 3 months.  We may keep some Personal Data for longer if there is a legal or tax reason for us doing so and we may use it for marketing purposes.</p>
               <h3>Sharing Personal Data</h3>
               <p>
                   The Personal Data you submit to us may be passed to the third party service providers we use to provide services to us, including accepting and making payments.
                   We may transfer your Personal Data to data processors within and outside the European Economic Area. If we do so, we will apply appropriate measures and controls to protect your Personal Data in accordance with applicable data protection laws, regulations and regulatory guidance. In all instances, we will take into account the nature of the Personal Data we are transferring, and the level of protection provided by those processors.
               </p>
               <h3>Rights that you have</h3>
               <p>As your Personal Data is held by us you have rights under GDPR that we will always respect. Your rights are:</p>
               <ul>
                <li>The right to request a copy of the Personal Data which we hold about you without charge;</li>
                <li>The right to request that we correct any incorrect or out of date Personal Data we hold;</li>
                <li>The right to ask us to erase Personal Data when it is no longer necessary for us to keep it;</li>
                <li>The right to withdraw any consent you have given to the processing of your data, at any time;</li>
                <li>The right to request that we provide you with details of the Personal Data we hold about you;</li>
                <li>The right to send your Personal Data directly to another data controller, so that they can perform a contract with you;</li>
                <li>The right, if you think the Personal Data we hold is wrong, to stop us processing that Personal Data until the issue is resolved;</li>
                <li>The right to object to the processing of Personal Data, including for marketing purposes;</li>
                <li>The right to lodge a complaint with the Information Commissioner’s Office (the ICO).</li>
               </ul>
               <h3>New purpose</h3>
               <p>If we wish to use your Personal Data for a new purpose not covered by this Privacy Notice, then we will provide you with a new notice explaining what we are proposing. We will tell you what we propose and point out new uses or conditions that we wish to apply. We will not process any Personal Data without your consent.</p>
               <h3>Other websites</h3>
               <p>The Site may contain links to other websites. This Privacy Notice only applies to our Site so, when you link to other websites, you should read and understand their Privacy Notice.</p>
               <h3>Contacting us and updating your Personal Data</h3>
               <p>If you wish to update, correct or change your Personal Data, how we use it or to make a complaint about what we are doing, you can do so by emailing us at <a href='mailto:support@therapology.io '>support@therapology.io </a>.</p>
               <p>If you make a complaint about how we use your Personal Data and we don’t resolve it your satisfaction, you can contact the Information Commissioner’s Office via their website <a href='(www.ico.org.uk)'>(www.ico.org.uk)</a>.</p>
               <p>We will occasionally update this Privacy Notice. We encourage you to review this Privacy Notice, from time to time, so that you can see any changes and remind yourself as to how we use your Personal Data.</p>
               <p className='c-terms__privacy'>This Privacy Notice was last updated April 2020</p>
            </div>
		</div>
	);
}

export default Terms;