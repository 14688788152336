import React from 'react';
import Menu from './burger-menu';

const logo = require("../../../images/logo.png");

const Header = (props) => {

	const onBurgerClick = () => {
		const { toggleDrawerMenu } = props;

		toggleDrawerMenu(true);
	};

	return (
		<div className="c-header" id="outer-container">
			<div className="c-header__burger">
				<Menu pageWrapId={"page-wrap"} outerContainerId={"outer-container"} />
			</div>
			<div className="c-header__inner" id="page-wrap">
				<div className="c-header__logo-wrap">
					<img src={logo} className="c-header__logo" alt="" />
				</div>

				<div className="c-header__navigation-bar">
					<nav>
						<ul>
							<li className="c-header__navigation-bar-links"><a href="/">
								Home
							</a></li>
							
							<li className="c-header__navigation-bar-button">
							   <a href="https://app.therapology.io/account/register">
								    <div className="c-btn c-btn--primary c-btn__text--center">
										Get started for free		
								    </div>
							    </a>
							</li>
							<li className="c-header__navigation-bar-links"><a href="https://app.therapology.io/account/login">
								Login
							</a></li>
						</ul>
					</nav>

				</div>

			</div>


		</div>

	);
};

export default Header;