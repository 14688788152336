import React from "react";
import {
	BrowserRouter as Router,
	Routes,
	Route
} from "react-router-dom";

import Home from "./components/home";
import About from "./components/about";
import ContactUs from "./components/contact-us";
import FAQ from "./components/faq";
import Features from "./components/features";
import Terms from "./components/terms";

const ApplicationRouter = (props) => {
	const header = props.header || null;
	const footer = props.footer;

	return (
		<Router>
			{header}

			<Routes>
				<Route path="/" element={<Home />}></Route>
				<Route path="/about" element={<About />}></Route>
				<Route path="/contact-us" element={<ContactUs />}></Route>
				<Route path="/faq" element={<FAQ />}></Route>
				<Route path="/features" element={<Features />}></Route>
				<Route path="/terms" element={<Terms />}></Route>
			</Routes>

			{footer}
		</Router>
	);
};

export default ApplicationRouter;